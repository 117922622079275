<template>
    <div class="doc_block">
        <p>Privacy Policy</p>
        <p>Introduction</p>
        <p>Welcome to Bot Hunter, a Telegram bot that allows users to mine and collect CATH. We are committed to protecting
        your privacy. This Privacy Policy explains what information we collect, how we use it, and your rights regarding
        your information.</p>

        <p>1. Information We Collect</p>
        <p>We only collect the following information from our users:</p>

        <p>  Telegram Account ID: This is used to uniquely identify you within the Bot Hunter system.
        First Name and Last Name: These are collected from your Telegram account to personalize your experience.
        Public TON Wallet Address: This is used to manage and verify transactions related to your CATH.</p>
        <p>2. How We Use Your Information</p>
        <p>The information we collect is used for the following purposes:</p>

        <p>To Operate and Maintain Bot Hunter: Your Telegram Account ID, First Name, and Last Name are used to manage your
            account and personalize your experience.</p>
        <p>To Facilitate Transactions: Your public TON Wallet Address is used to manage and verify transactions related to
            your CATH.</p>
        <p>3. Information Sharing and Disclosure</p>
        <p>We do not share, sell, rent, or trade your personal information with third parties except in the following
            circumstances:</p>

        <p>Legal Requirements: We may disclose your information if required to do so by law or in response to a valid
            request from a law enforcement authority or other government officials.</p>
        <p>4. Data Security</p>
        <p>We take reasonable measures to protect your information from unauthorized access or against loss, misuse, or
        alteration by third parties. However, no method of transmission over the Internet or electronic storage is 100%
        secure, so we cannot guarantee its absolute security.</p>

        <p>5. Your Rights</p>
        <p>You have the following rights regarding your information:</p>

        <p>Access: You have the right to access the personal information we hold about you.
        Correction: You have the right to request that we correct any inaccuracies in your personal information.
        Deletion: You have the right to request that we delete your personal information.</p>
        <p>6. Changes to This Privacy Policy</p>
        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we will notify
            you of any significant changes by contacting you through Telegram or by other means.</p>

        <p>7. Contact Us</p>
        <p>If you have any questions about this Privacy Policy or our data practices, please contact us at https://t.me/cathunterx_bot.</p>

        <p>By using Bot Hunter, you acknowledge that you have read, understood, and agree to be bound by this Privacy
            Policy.</p>
    </div>
</template>
<script>
export default {
    /* eslint-disable */
    name: "PrivacyPolicy"
}
</script>