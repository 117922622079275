<template>
    <div>
        <div class="screen_title">Up Level</div>
        <div class="screen_sub_title">
            Upgrade for more energy!
        </div>
        <div class="invite_block">
            <div class="invite_item" v-for="level in levels" :key="level">
                <div class="invite_item_left">
                    <div class="invite_item_level">
                        <div class="invite_item_level_box">
                            {{ level.level }}
                        </div>
                    </div>
                    <div class="invite_item_info">
                        <div class="invite_item_level">
                            Level {{ level.level }}
                        </div>
                        <div class="invite_item_name">
                            MAX {{ level.max }} Energy
                        </div>
                    </div>
                </div>
                <div class="invite_item_right" v-if="user && user.level < level.level">
                    <div>{{ numberWithCommas(level.price) }} CATH</div>
                    <div class="upgrade_button"><button @click="onClickUpgrade(level.level)">Upgrade</button></div>
                </div>
                <div class="invite_item_right" v-if="user && user.level === level.level">
                    <div>ACTIVE</div>
                </div>
                <div class="invite_item_right" v-if="user && user.level !== level.level && user.level > level.level">
                    <div>PASS</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
export default {
    /* eslint-disable */
    name: "Upgrade",
    data() {
        return {
            levels: [],
            user: null
        }
    },
    methods: {
        getLevels() {
            http.get("/user/level").then(res => {
                if (res.data.status === 200) {
                    this.levels = res.data.levels;
                }
            });
        },
        getUserInfor() {
            http.get("/user/infor", {
                params: {
                    account: localStorage.getItem("user_account"),
                    game: localStorage.getItem("game")
                }
            }).then(res => {
                if (res.data.status === 200) {
                    this.user = res.data.user;
                }
            });
        },
        onClickUpgrade(level) {
            http.post("/user/up-level", {
                level: level,
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game")
            }).then(res => {
                if (res.data.status === 200) {
                    this.getUserInfor();
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "error"
                    });
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
    mounted() {
        this.getLevels();
        this.getUserInfor();
    }
}
</script>