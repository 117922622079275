<template>
    <div class="doc_block">
        <p>Terms of Use</p>
        <p>Introduction</p>
        <p>Welcome to Bot Hunter, a Telegram bot that allows users to mine and collect CATH. By using Bot Hunter, you agree
        to comply with and be bound by the following terms of use. Please read these terms carefully before using our
        service. If you do not agree to these terms, you may not use Bot Hunter.</p>

        <p>1. Acceptance of Terms</p>
        <p>By accessing and using Bot Hunter, you accept and agree to be bound by these Terms of Use and our Privacy
            Policy. These terms apply to all users of the bot.</p>

        <p>2. Description of Service</p>
        <p>Bot Hunter provides users with the ability to mine and collect CATH through interactive gameplay on the Telegram
        platform. The specifics of how CATH can be mined and collected will be provided within the bot interface.
        </p>

        <p>3. User Accounts</p>
        <p>To use Bot Hunter, you may need to create an account. You are responsible for maintaining the confidentiality of
        your account and password and for restricting access to your device. You agree to accept responsibility for all
        activities that occur under your account.</p>

        <p>4. CATH Collection</p>
        <p>Users can collect CATH by participating in various activities within Bot Hunter. The value and usability of CATH
        are determined solely by Bot Hunter and may change at any time. Bot Hunter does not guarantee any monetary value
        or external usability for CATH.</p>

        <p>5. Prohibited Activities</p>
        <p>Users agree not to engage in any of the following prohibited activities:</p>

        <p>Using Bot Hunter for any illegal purposes.</p>
        <p>Attempting to gain unauthorized access to Bot Hunter, other user accounts, or computer systems or networks
            connected to Bot Hunter.</p>
        <p>Engaging in any activity that disrupts or interferes with the proper functioning of Bot Hunter.</p>
        <p>Using automated systems, such as bots or scripts, to collect CATH or interact with Bot Hunter.</p>
        <p>6. Termination</p>
        <p>Bot Hunter reserves the right to terminate or suspend your account and access to the bot at its sole discretion,
        without notice, for conduct that Bot Hunter believes violates these Terms of Use or is harmful to other users of
        the bot, Bot Hunter, or third parties, or for any other reason.</p>

        <p>7. Disclaimer of Warranties</p>
        <p>Bot Hunter is provided "as is" and "as available" without warranties of any kind, either express or implied,
        including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or
        non-infringement. Bot Hunter does not warrant that the service will be uninterrupted, error-free, or free of
        viruses or other harmful components.</p>

        <p>8. Limitation of Liability</p>
        <p>In no event shall Bot Hunter, its owners, or its affiliates be liable for any indirect, incidental, special,
        consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly,
        or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use
        Bot Hunter; (ii) any unauthorized access to or use of our servers and/or any personal information stored
        therein; (iii) any interruption or cessation of transmission to or from Bot Hunter; (iv) any bugs, viruses,
        Trojan horses, or the like that may be transmitted to or through Bot Hunter by any third party; (v) any errors
        or omissions in any content or for any loss or damage incurred as a result of your use of any content posted,
        emailed, transmitted, or otherwise made available through Bot Hunter; and/or (vi) the defamatory, offensive, or
        illegal conduct of any third party.</p>

        <p>9. Changes to Terms of Use</p>
        <p>Bot Hunter reserves the right to modify these Terms of Use at any time. Changes will be posted on this page, and
        your continued use of Bot Hunter after any changes have been made will constitute your acceptance of the new
        terms.</p>

        <p>10. Contact Information</p>
        <p>If you have any questions about these Terms of Use, please contact us at: https://t.me/cathunterx_bot</p>

        <p>By using Bot Hunter, you acknowledge that you have read, understood, and agree to be bound by these Terms of
            Use.</p>
    </div>
</template>
<script>
export default {
    /* eslint-disable */
    name: "TermOfUse"
}
</script>