<template>
    <div>
        <div v-if="isReset" class="wheel_block">
            <div class="wheel">
                <div class="wheel__inner" id="wheel__inner_spin">
                    <!-- XANH LA -->
                    <div class="wheel__sec">
                        <div class="wheel_value">1 TON</div>
                    </div>
                    <!-- XANH DA TROI -->
                    <div class="wheel__sec">
                        <div class="wheel_value">0.05 TON</div>
                    </div>
                    <!-- XANH DAM -->
                    <div class="wheel__sec">
                        <div class="wheel_value">0.01 TON</div>
                    </div>
                    <!-- VANG -->
                    <div class="wheel__sec">
                        <div class="wheel_value">0 TON</div>
                    </div>
                    <!-- CAM -->
                    <div class="wheel__sec">
                        <div class="wheel_value">0.5 TON</div>
                    </div>
                    <div class="wheel__sec">
                        <div class="wheel_value">0.1 TON</div>
                    </div>
                </div>
                <div class="wheel__arrow">
                    <button class="wheel__button" @click="onSpin()">SPIN</button>
                </div>
            </div>
        </div>
        <lottie-animation path="loading_clock.json" width="300" height="350" class="loading_clock_animation"
            v-if="!isReset" />
        <div class="wheel_plain_text">
            <div>1 SPIN = 1,000,000 CATH</div>
            <div class="ton_balance">You Have: {{ numberWithCommas(balance) }} CATH</div>
            <div>
                <div class="ton_balance">You Got: {{ numberWithCommas(ton.toFixed(2)) }} TON</div>
                <button :disabled="claimDisable" class="ton_claim_button" @click="claimTon()">Claim TON</button>
            </div>
        </div>
        <div class="invite_title">
            Top 10 Transactions
        </div>
        <div class="invite_block">
            <div class="invite_item" v-for="(item, index) in histories" :key="item">
                <div class="invite_item_left">
                    <div class="invite_item_level">
                        <div class="invite_item_level_box">
                            {{ index + 1 }}
                        </div>
                    </div>
                    <div class="invite_item_info">
                        <div class="invite_item_level" v-if="item.type === 'spin'">
                            +{{ item.result }}
                        </div>
                        <div class="invite_item_level" v-else>
                            -{{ parseFloat(item.value).toFixed(2) }} TON
                        </div>
                        <div class="invite_item_name">
                            {{ item.type === 'spin' ? 'SPIN' : 'CLAIM' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
    /* eslint-disable */
    name: "Spin",
    components: {
        LottieAnimation
    },
    data() {
        return {
            position: 0,
            countClicked: 0,
            clicked: false,
            ton: 0,
            balance: 0,
            value: 0,
            isReset: true,
            histories: [],
            claimDisable: false
        }
    },
    methods: {
        async getValue() {
            return http.post("/transaction/spin", {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game"),
                init: this.value
            }, {}).then(res => {
                return res;
            });
        },
        getUserInfor() {
            http.get("/user/infor", {
                params: {
                    account: localStorage.getItem("user_account"),
                    game: localStorage.getItem("game")
                }
            }).then(res => {
                if (res.data.status === 200) {
                    this.ton = res.data.user.ton;
                    this.balance = res.data.user.balance;
                }
            });
        },
        async claimTon() {
            this.isReset = false;
            this.claimDisable = true;
            return http.post("/transaction/claim", {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game"),
            }, {}).then(res => {
                this.claimDisable = false;
                this.isReset = true;
                this.getUserInfor();
                this.getHistory();

                if (res.data.status === 200) {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "success"
                    });
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "error"
                    });
                }
            });
        },
        onSpin() {
            this.getValue().then(res => {
                if (res.data.status === 200) {
                    if (this.value === 0) {
                        this.value += res.data.position;
                    } else {
                        this.value = res.data.position;
                    }
                    document.getElementById('wheel__inner_spin').style.transform = `rotate(${this.value}deg)`;
                    setTimeout(() => {
                        this.getPosition(this.value % 360);
                    }, 5000);
                } else {
                    this.$notify({
                        title: "MESSAGE",
                        text: res.data.message,
                        type: "error"
                    });
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        getPosition(position) {
            this.getUserInfor();
            this.getHistory();

            if (position <= 30) {
                this.$notify({
                    title: "MESSAGE",
                    text: "+1 TON",
                    type: "success"
                });
            }
            else if (position <= 90) {
                this.$notify({
                    title: "MESSAGE",
                    text: "+0.1 TON",
                    type: "success"
                });
            }
            else if (position <= 150) {
                this.$notify({
                    title: "MESSAGE",
                    text: "+0.5 TON",
                    type: "success"
                });
            } else if (position <= 210) {
                this.$notify({
                    title: "MESSAGE",
                    text: "+0 TON",
                    type: "error"
                });
            } else if (position <= 270) {
                this.$notify({
                    title: "MESSAGE",
                    text: "+0.01 TON",
                    type: "success"
                });
            }
            else if (position <= 330) {
                this.$notify({
                    title: "MESSAGE",
                    text: "+0.05 TON",
                    type: "error"
                });
            }
            else {
                this.$notify({
                    title: "MESSAGE",
                    text: "+1 TON",
                    type: "success"
                });
            }
            this.clicked = false;
            this.countClicked = 0;
            this.isReset = false;
            this.value = 0;
            document.getElementById('wheel__inner_spin').style.transform = `rotate(${this.value}deg)`;

            setTimeout(() => {
                this.isReset = true;
            }, 2000);
        },
        async getHistory() {
            return http.get("/user/spin-history", {
                params: {
                    account: localStorage.getItem("user_account"),
                    game: localStorage.getItem("game")
                }
            }).then(res => {
                if (res.data.status === 200) {
                    this.histories = res.data.data;
                }
            });
        }
    },
    mounted() {
        this.getUserInfor();
        this.getHistory();
    }
}
</script>
