import { createWebHistory, createRouter } from "vue-router";
import Home from "@/components/Home.vue";
import Task from "@/components/Task.vue";
import Invite from "@/components/Invite.vue";
import Wallet from "@/components/Wallet.vue";
import Upgrade from "@/components/Upgrade.vue";
import Spin from "@/components/Spin.vue";
import TermOfUse from "@/components/TermOfUse.vue";
import Privacy from "@/components/Privacy.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/task",
        name: "Task",
        component: Task,
    },
    {
        path: "/invite",
        name: "Invite",
        component: Invite,
    },
    {
        path: "/wallet",
        name: "Wallet",
        component: Wallet,
    },
    {
        path: "/upgrade",
        name: "Upgrade",
        component: Upgrade
    },
    {
        path: "/spin",
        name: "Spin",
        component: Spin
    },
    {
        path: "/term-of-use",
        name: "TermOfUse",
        component: TermOfUse
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: Privacy
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;