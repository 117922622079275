<template>
    <div>
        <div class="screen_title">Pending Tasks</div>
        <div class="screen_sub_title">Earn 50% for your direct referrals!</div>
        <div class="main_point">
            <count-up :end-val="endVal" :start-val="startVal"></count-up>
            <span>CATH</span>
        </div>
        <div class="claim_button">
            <button @click="claimPending()">Claim</button>
        </div>
        <div class="share_link">
            <button @click="doCopy('https://t.me/cathunterx_bot?start=' + refCode);proccessCopy()">Copy Link Invite</button>
        </div>
        <div class="invite_title">
            {{ numberWithCommas(totalRef) }} Frens - Top 10 Frens
        </div>
        <div class="invite_block">
            <div class="invite_item" v-for="ref in refList" :key="ref">
                <div class="invite_item_left">
                    <div class="invite_item_level">
                        <div class="invite_item_level_box">
                            {{ ref.level }}
                        </div>
                    </div>
                    <div class="invite_item_info">
                        <div class="invite_item_level">
                            Level {{ ref.level }}
                        </div>
                        <div class="invite_item_name">
                            {{ ref.username }}
                        </div>
                    </div>
                </div>
                <div class="invite_item_right">
                    {{ numberWithCommas(ref.balance) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CountUp from 'vue-countup-v3';
import http from "@/http";
import { copyText } from 'vue3-clipboard'
export default {
    /* eslint-disable */
    name: "Invite",
    components: {
        CountUp
    },
    setup() {
        const doCopy = (text) => {
            copyText(text, undefined, (error, event) => {
                if (error) {
                    console.log(error);
                } else {
                    console.log("DONE");
                }
            });
        };

        return { doCopy }
    },
    data() {
        return {
            endVal: 0,
            startVal: 0,
            refCode: "",
            totalRef: 0,
            refList: []
        }
    },
    methods: {
        getUserInfor() {
            http.get("/user/infor", {
                params: {
                    account: localStorage.getItem("user_account"),
                    game: localStorage.getItem("game")
                }
            }).then(res => {
                if (res.data.status === 200) {
                    this.endVal = res.data.user.pending;
                    this.refCode = res.data.user.refCode;
                    this.getInviteInfor();
                }
            });
        },
        getInviteInfor() {
            http.get("/user/invite-infor", {
                params: {
                    account: localStorage.getItem("user_account"),
                    game: localStorage.getItem("game"),
                    ref: this.refCode
                }
            }).then(res => {
                if (res.data.status === 200) {
                    this.totalRef = res.data.refs;
                    this.refList = res.data.listRefs;
                }
            });
        },
        claimPending() {
            http.post("/user/claim-pending", {
                account: localStorage.getItem("user_account"),
                game: localStorage.getItem("game")
            }, {}).then(res => {
                if (res.data.status === 200) {
                    this.startVal = this.endVal;
                    this.endVal = 0;
                    this.$notify({
                        title: "MESSAGE",
                        text: "Claimed!",
                        type: "success"
                    });
                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        proccessCopy() {
            this.$notify({
                title: "MESSAGE",
                text: "Copied!",
                type: "success"
            });
        }
    },
    mounted() {
        this.getUserInfor();
    }
}
</script>