<template>
  <div>
    <div class="header" v-if="checkHideLayout()">
      <div class="header_info">
        <div class="header_info_img">
          <div class="header_info_img_block">
            {{ user ? user.level : 0 }}
          </div>
        </div>
        <div class="header_info_text">
          <div>Level {{ user ? user.level : 0 }}</div>
          <div>{{ user ? user.username : "---" }}</div>
        </div>
      </div>
      <div class="header_action">
        <button @click="goToUpgrade()">Up Level</button>
      </div>
    </div>
    <div class="containter_block">
      <router-view></router-view>
    </div>
    <notifications />
    <div class="footer" v-if="checkHideLayout()">
      <div :class="['footer_item', checkActiveMenu('Home') ? 'active' : '']">
        <a href="/">
          <div class="footer_icon"><rocket-launch-icon class="icon" /></div>
          <div class="footer_text">Huting</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('Task') ? 'active' : '']">
        <a href="/task">
          <div class="footer_icon"><beaker-icon class="icon" /></div>
          <div class="footer_text">Task</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('Spin') ? 'active' : '']">
        <a href="/spin">
          <div class="footer_icon"><LifebuoyIcon class="icon" /></div>
          <div class="footer_text">SPIN</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('Invite') ? 'active' : '']">
        <a href="/invite">
          <div class="footer_icon"><qr-code-icon class="icon" /></div>
          <div class="footer_text">Invite</div>
        </a>
      </div>
      <div :class="['footer_item', checkActiveMenu('Wallet') ? 'active' : '']">
        <a href="/wallet">
          <div class="footer_icon"><credit-card-icon class="icon" /></div>
          <div class="footer_text">Wallet</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { BeakerIcon, RocketLaunchIcon, QrCodeIcon, CreditCardIcon, LifebuoyIcon } from '@heroicons/vue/24/solid';
import http from "@/http";
export default {
  name: 'App',
  components: {
    BeakerIcon,
    RocketLaunchIcon,
    QrCodeIcon,
    CreditCardIcon,
    LifebuoyIcon
  },
  data() {
    return {
      user: null
    }
  },
  methods: {
    checkActiveMenu(name) {
      if (this.$route.name && this.$route.name === name) {
        return true;
      } else {
        return false;
      }
    },
    checkHideLayout() {
      if(this.$route.name === "TermOfUse" || this.$route.name === "PrivacyPolicy") {
        return false;
      }

      return true;
    },
    getUserInfor() {
      http.get("/user/infor", {
        params: {
          account: localStorage.getItem("user_account"),
          game: localStorage.getItem("game")
        }
      }).then(res => {
        if (res.data.status === 200) {
          this.user = res.data.user;
        }
      });
    },
    goToUpgrade() {
      this.$router.push({ name: "Upgrade" });
    }
  },
  mounted() {
    this.getUserInfor();
  }
}
</script>

<style lang="scss">
@import "@/assets/style.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
